var Global = {
    /*************** Desarrollo *****************/
    //API REST de AZURE
    //apiRESTUrl : "http://localhost:3001/api",      
  
  
    /*************** Produccion *****************/
    //API REST de AZURE
    apiRESTUrl : "https://webapigovinetcrm.azurewebsites.net/api",
   
    //Azure StorageGovi(fotos)
    UrlFotosWeb : "https://storagegovi.blob.core.windows.net/crm/",
    };

export default Global;
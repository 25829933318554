import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert";
import Header from "./Header";
import HomeActions from "./HomeActions.js";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { obtieneTop10Entrevistas } from "../repository";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      pedidos: {},
      entrevistas: {},
      status: null,
      loading: 1,
    };
  }

  obtieneTop10Entrevistas = () => {
    var idUsuario = localStorage.getItem("idUsuario");
    obtieneTop10Entrevistas(idUsuario)
      .then((response) => {
        if (response.status === "success") {
          this.setState({
            status: "success",
            entrevistas: response.result,
            loading: 0,
          });
        } else {
          Swal(
            "Error obtener la información [Entrevistas]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Entrevistas]", err, "error")
      );
  };

  componentDidMount() {
    this.obtieneTop10Entrevistas();
  }

  render() {
    return (
      <div>
        {/* NAVBAR */}
        <NavBar />
        {/* HEADER */}
        <Header
          title="CRM"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-address-book-o"
        />
        {/* ACTIONS */}
        <HomeActions />
        {/* PEDIDOS */}
        <section id="encuestas">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-2">
                {this.state.loading === 1 && (
                  <div className="text-center mt-3">
                    <h6>Cargando...</h6>
                    <div
                      className="spinner-border text-warning"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                )}
                {this.state.loading === 0 && (
                  <div className="card">
                    <div className="card-header">
                      <h5>Últimas 10 Encuestas</h5>
                    </div>
                    <table className="table-sm table-striped w-100">
                      <thead className="table-dark">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Cliente</th>

                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      {this.state.status === "success" && (
                        <tbody>
                          {this.state.entrevistas.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td className="text-center">{item.id}</td>
                                <td className="text-center">
                                  {item.strNombreCliente
                                    ? item.strNombreCliente.length > 15
                                      ? item.strNombreCliente.substring(0, 15) +
                                        "..."
                                      : item.strNombreCliente
                                    : ""}
                                </td>
                                {
                                  <td className="text-center">
                                    <Link
                                      to={"/EncuestaDetalle/" + item.id + "/1"}
                                      className="btn btn-secondary"
                                    >
                                      <i className="fas fa-angle-double-right"></i>{" "}
                                      Detalle
                                    </Link>
                                  </td>
                                }
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default Home;

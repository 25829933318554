import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomeActions extends Component {
  render() {
    return (
      <section id="actions" className="py-3">
        <div className="container">
          <div className="row">            
            <div className="col-md-3  mb-2">              
              <Link to={"/Encuesta"} className="btn btn-warning btn-block">
                <i className="fas fa-file-alt"></i>&nbsp;Nueva Encuesta
              </Link>
              </div>              
          </div>
        </div>
      </section>
    );
  }
}

export default HomeActions;

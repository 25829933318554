import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class NavBarItem extends Component {
  render() {
    const { item } = this.props;
    return (
      <li className="nav-item px-2">
        <NavLink
          to={item.Ruta}
          className="nav-link"
          activeClassName="nav-link active"
        >
          <h6>
            {item.Module}{" "}
            {item.Globo === 1 ? (
              <span className="badge badge-success">
                {this.props.cartItems}
              </span>
            ) : (
              ""
            )}
            {this.props.item.Globo === 2 ? (
              <span className="badge badge-warning">
                {this.props.orderItems}
              </span>
            ) : (
              ""
            )}
          </h6>
        </NavLink>
      </li>
    );
  }
}

export default NavBarItem;

import axios from "axios";
import global from "./components/Global";
const FormData = require("form-data");

export function login(data) {
  var usuario = data.usuario;
  var contrasenia = data.contrasenia;
  var apiCall = global.apiRESTUrl + "/login/" + usuario + "/" + contrasenia;
  return axios
    .get(apiCall)
    .then((response) => {
      if (response.data.status === "success") {
        sessionStorage.setItem("usuario", JSON.stringify(response.data.result));
        //Token
        sessionStorage.setItem("x-access-token", response.data.token);
        //Token expira en 12 horas
        sessionStorage.setItem(
          "x-access-token-expiration",
          Date.now() + 12 * 60 * 60 * 1000
        );
      }
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return error.response.data;
    });
}

export function isAuthenticated() {
  return (
    sessionStorage.getItem("x-access-token") &&
    sessionStorage.getItem("x-access-token-expiration") > Date.now()
  );
}

export function obtieneSucursalesEncargados() {
  var apiCall = global.apiRESTUrl + "/obtieneSucursalesEncargados";
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response.data;
    });
}

export function obtieneSucursalesEncargadosTodos() {
  var apiCall = global.apiRESTUrl + "/obtieneSucursalesEncargadosTodos";
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log(error);
      return error.response.data;
    });
}

export function obtieneEncuesta() {
  var apiCall = global.apiRESTUrl + "/obtieneEncuestas";
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log(error);
      return error.response.data;
    });
}

export function obtieneTipoCliente() {
  var apiCall = global.apiRESTUrl + "/obtieneTipoCliente";
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log(error);
      return error.response.data;
    });
}

export function obtienetipoVisita() {
  var apiCall = global.apiRESTUrl + "/obtieneTipoVisita";
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log(error);
      return error.response.data;
    });
}

export function obtieneEncuestaPorAlmacenFecha(
  idAlmacen,
  fechaInicio,
  fechaFin,
  idUsuario
) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneEntrevistaPorAlmacenFecha/" +
    idAlmacen +
    "/" +
    fechaInicio +
    "/" +
    fechaFin +
    "/" +
    idUsuario;
  //console.log(apiCall);
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log(error);
      return error.response.data;
    });
}

export function obtieneEncuestaPorId(id) {
  var apiCall = global.apiRESTUrl + "/obtieneEntrevistaPorAlmacenFecha/" + id;
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log(error);
      return error.response.data;
    });
}

export async function agregaEntrevista(
  idUsuario,
  idSucursal,
  nombreCliente,
  domicilio,
  tipoCliente,
  tipoVisita,
  mayorCompra,
  problematicaCompra,
  requerimientosCompra,
  rutaFoto,
  correo,
  telefonoLocal,
  antiguedad,
  telefonoMovil,
  razonNoCompra,
  idEncuesta
) {
  var apiCall = global.apiRESTUrl + "/guardaEncuestaCliente";
  const response = await axios
    .post(apiCall, {
      idUsuario: idUsuario,
      idSucursal: idSucursal,
      nombreCliente: nombreCliente,
      domicilio: domicilio,
      tipoCliente: tipoCliente,
      tipoVisita: tipoVisita,
      mayorCompra: mayorCompra,
      problematicaCompra: problematicaCompra,
      requerimientosCompra: requerimientosCompra,
      rutaFoto: rutaFoto,
      correo: correo,
      telefonoLocal: telefonoLocal,
      antiguedad: antiguedad,
      telefonoMovil: telefonoMovil,
      razonNoCompra: razonNoCompra,
      idEncuesta: idEncuesta,
    })
    .catch((error) => {
      return error;
    });
  try {
    if (response.data.status === "success") {
      return response.data.result[0].idEncuesta;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function guardaFotoAzure(img, idEncuesta) {
  var form = new FormData();
  form.append("image", img);
  form.append("idEncuesta", idEncuesta);
  var apiCall = global.apiRESTUrl + "/guardaImagenAzure";
  await axios
    .post(apiCall, form)
    .then(function () {})
    .catch((error) => {
      return error;
    });
}

export function obtieneTop10Entrevistas(id) {
  var apiCall = global.apiRESTUrl + "/obtieneTop10Entrevistas/" + id;
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log(error);
      return error.response.data;
    });
}

export async function agregaFotosDetalle(idEncuesta, strNombreFoto) {
  var apiCall = global.apiRESTUrl + "/guardaFotoDetalles"; 
  const response = await axios
    .post(apiCall, {
      idEncuesta: idEncuesta,
      strNombreFoto: strNombreFoto,
    })
    .catch((error) => {
      return error;
    });
  try {
    if (response.data.status === "success") {
      return 1;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export function obtieneFotosEncuesta(idEncuesta) {
  var apiCall = global.apiRESTUrl + "/obtieneFotosEncuesta/" + idEncuesta;
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //console.log(error);
      return error.response.data;
    });
}

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import {
  obtieneEncuestaPorAlmacenFecha,
  obtieneSucursalesEncargadosTodos,
} from "../repository";
import NavBar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

class ReporteEncuestas extends Component {
  constructor() {
    super();
    this.handleComboChange = this.handleComboChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.consultaEncuestaAutomatica = this.consultaEncuestaAutomatica.bind(
      this
    );

    this.state = {
      status: null,
      loading: 0,
      sucursales: [],
      fechaInicio: new Date().toISOString().split("T")[0],
      fechaFin: new Date().toISOString().split("T")[0],
      entrevistas: [],
      idSucursal: -1,
      loading2: 1,
    };

    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    });
    this.obtieneSucursalesCRM();
  }

  handleInputChange(event) {
    if (this.state.entrevistas !== undefined) {
      if (event.target.name === "fechaInicio") {
        if (event.target.value > this.state.fechaFin) {
          this.setState({
            fechaInicio: this.state.fechaFin,
            entrevistas: [],
          });
          Swal(
            "Aviso",
            "La fecha inicio no puede ser mayor a la fecha fin.",
            "warning"
          );
        } else {
          this.setState({
            fechaInicio: event.target.value,
            entrevistas: [],
          });
        }
      }

      if (event.target.name === "fechaFin") {
        if (event.target.value < this.state.fechaInicio) {
          this.setState({
            fechaFin: this.state.fechaInicio,
            entrevistas: [],
          });
          Swal(
            "Aviso",
            "La fecha fin no puede ser menor a la fecha inicio.",
            "warning"
          );
        } else {
          this.setState({
            fechaFin: event.target.value,
            entrevistas: [],
          });
        }
      }
    } else {
      console.log("encuestas con info");
    }
  }

  handleComboChange(event) {
    if (event.target.name === "idSucursal") {
      this.setState({ idSucursal: event.target.value, entrevistas: [] });
      console.log(event.target);
    }
  }

  obtieneSucursalesCRM = () => {
    obtieneSucursalesEncargadosTodos()
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.idAlmacen, text: item.strDescripcion };
          });
          this.setState({
            sucursales: [{ value: 0, text: "[Seleccione]" }].concat(items),
            loading: 0,
            loading2: 0,
          });
          if (this.props.match !== undefined) {
            var elem = document.getElementsByName("idSucursal")[0];
            elem.value = this.state.idSucursal;
          }
        } else {
          Swal(
            "Error obtener la información [Sucursales]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Sucursales]", err, "error")
      );
  };

  componentDidMount() {
    //validar this.props.match.params que no venga vacio
    if (this.props.match === undefined) {
    } else {
      this.setState(
        {
          loading: 1,
          fechaInicio: this.props.match.params.fechaInicio,
          fechaFin: this.props.match.params.fechaFin,
          idSucursal: this.props.match.params.idSucursal,
        },
        function () {
          this.consultaEncuestaAutomatica(
            this.state.idSucursal,
            this.state.fechaInicio,
            this.state.fechaFin
          );
        }
      );
    }
  }

  consultaEncuestaClick = async (e) => {
    e.preventDefault();
    this.setState({ loading: 1 });
    this.consultaEncuesta();
  };

  consultaEncuesta = () => {
    this.setState({ entrevistas: [] });
    if (this.validator.allValid()) {
      var idUsuario = localStorage.getItem("idUsuario");
      obtieneEncuestaPorAlmacenFecha(
        this.state.idSucursal,
        this.state.fechaInicio,
        this.state.fechaFin,
        idUsuario
      )
        .then((response) => {
          if (response.status === "success" && response.result) {
            this.setState({
              status: "success",
              entrevistas: response.result,
              loading: 0,
            });
          } else {
            this.setState({ status: "error", entrevistas: [], loading: 0 });
            Swal("Aviso", "No se encontró información", "info");
          }
        })
        .catch((err) =>
          Swal("Error obtener la información [Entrevistas]", err, "error")
        );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: 0 });
    }
  };

  consultaEncuestaAutomatica = (pfidSucursal, pffechaInicio, pffechaFin) => {
    this.setState({ entrevistas: [] });
    var idUsuario = localStorage.getItem("idUsuario");
    obtieneEncuestaPorAlmacenFecha(
      pfidSucursal,
      pffechaInicio,
      pffechaFin,
      idUsuario
    )
      .then((response) => {
        if (response.status === "success" && response.result) {
          this.setState({
            status: "success",
            entrevistas: response.result,
            loading: 0,
          });
        } else {
          this.setState({ status: "error", entrevistas: [], loading: 0 });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div>
        <NavBar />
        <Header
          title="Reporte de Encuestas"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-search"
        />
        {/* CONTENT */}
        <section id="searchContent">
          <div className="container">
            <div className="row">
              <div className="col-md-3 py-3">
                <Link to={"/home"} className="btn btn-secondary btn-block mb-2">
                  <i className="fas fa-arrow-left"></i> Regresar
                </Link>
              </div>
            </div>
            {this.state.loading2 === 1 && (
              <div className="text-center mt-3">
                <h6>Cargando...</h6>
                <div
                  className="spinner-border text-warning"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            )}
            {this.state.loading2 === 0 && (
              <div className="row mt-3">
                <div className="col-md-3 mb-3">
                  <h6>Sucursal:</h6>
                  <select
                    name="idSucursal"
                    key="idSucursal"
                    className="form-control"
                    selectedvalue={this.state.idSucusal}
                    onChange={this.handleComboChange}
                  >
                    {this.state.sucursales.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {this.validator.message(
                    "idSucursal",
                    this.state.idSucursal,
                    "required|numeric|min:1,num"
                  )}
                </div>
                <div className="col-md-3 mb-3">
                  <h6>Fecha Inicio:</h6>
                  <input
                    type="date"
                    id="fechaInicio"
                    name="fechaInicio"
                    format="DD/MM/YYYY"
                    className="form-control"
                    key="fechaInicio"
                    value={this.state.fechaInicio}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <div className="col-md-3 mb-3">
                  <h6>Fecha Fin:</h6>
                  <input
                    type="date"
                    id="fechaFin"
                    name="fechaFin"
                    format="DD/MM/YYYY"
                    className="form-control"
                    key="fechaFin"
                    value={this.state.fechaFin}
                    onChange={this.handleInputChange}
                  ></input>
                </div>
                <div className="col-md-3 mb-3">
                  <h6>&nbsp;</h6>
                  <button
                    className="btn btn-warning btn-block"
                    type="submit"
                    onClick={this.consultaEncuestaClick}
                  >
                    <i className="fas fa-search mr-1"></i>Buscar
                  </button>
                </div>
              </div>
            )}
            <div className="container">
              {this.state.loading === 1 && (
                <div className="text-center mt-3">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}
              <div className="row">
                {this.state.status === "success" &&
                  this.state.entrevistas.length > 0 && (
                    <table className="table-sm table-striped w-100">
                      <thead className="table-dark">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Cliente</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      {this.state.status === "success" && (
                        <tbody>
                          {this.state.entrevistas.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td className="text-center">{item.id}</td>
                                <td className="text-center">
                                  {item.strNombreCliente
                                    ? item.strNombreCliente.length > 15
                                      ? item.strNombreCliente.substring(0, 15) +
                                        "..."
                                      : item.strNombreCliente
                                    : ""}
                                </td>
                                {
                                  <td className="text-center">
                                    <Link
                                      to={
                                        "/EncuestaDetalle/" +
                                        item.id +
                                        "/2/" +
                                        this.state.idSucursal +
                                        "/" +
                                        this.state.fechaInicio +
                                        "/" +
                                        this.state.fechaFin
                                      }
                                      className="btn btn-secondary"
                                    >
                                      <i className="fas fa-angle-double-right"></i>{" "}
                                      Detalle
                                    </Link>
                                  </td>
                                }
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  )}
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default ReporteEncuestas;

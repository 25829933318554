import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import Logo from "../images/govinet.png";
import Footer from "./Footer";
import { login } from "../repository";

class Login extends Component {
  constructor() {
    super();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.state = {
      usuario: localStorage.getItem("usuario"),
      contrasenia: localStorage.getItem("contrasenia"),
      rememberMe: true,
      loading: 0,
    };
  }

  toggleRememberMe() {
    this.setState({ rememberMe: !this.state.rememberMe });
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitLogin(event) {
    event.preventDefault();
    this.setState({
      loading: 1,
    });
    if (this.validator.allValid()) {
      login(this.state)
        .then((response) => {
          if (response.status === "success" && response.result) {
            localStorage.setItem("rememberMe", this.state.rememberMe);
            localStorage.setItem("user", JSON.stringify(response.result));
            var userInfo = JSON.parse(JSON.stringify(response.result));
            var idUsuario = userInfo[0].id;
            localStorage.setItem("idUsuario", idUsuario);
            if (this.state.rememberMe) {
              localStorage.setItem("usuario", this.state.usuario);
              localStorage.setItem("contrasenia", this.state.contrasenia);
            } else {
              localStorage.setItem("usuario", "");
              localStorage.setItem("contrasenia", "");
            }
            window.location = "/Home";
          } else {
            Swal(
              "Error al Ingresar al Sistema",
              "Usuario y/o Contraseña Invalidos.",
              "error"
            );
            this.setState({ loading: 0 });
          }
        })
        .catch((err) => {
          Swal("Error de Conexón al Sistema", err, "error");
          this.setState({ loading: 0 });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: 0 });
    }
  }

  UNSAFE_componentWillMount() {
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        required: "Campo Requerido",
      },
    });
  }

  render() {
    return (
      <div>
        {/* NAVBAR */}
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark p-0">
          <div className="container">
            <div className="navbar-brand">
              &nbsp;GOVI Suspensi&oacute;n Automotriz
            </div>
          </div>
        </nav>
        {/* HEADER */}
        <header id="main-header" className="py-1 bg-warning text-black">
          <div className="container">
            <div className="row">
              <div className="col-md-0">
                &nbsp;
                <img src={Logo} width="15%" height="auto" alt="GoviNet" />{" "}
                <h1 className="float-right">GoviNet CRM</h1>
              </div>
            </div>
          </div>
        </header>

        {/* LOGIN */}
        <section id="login">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mx-auto mt-5">
                <div className="card">
                  <div className="card-header">
                    <h4 className="text-center">Ingreso al Sistema</h4>
                  </div>
                  <div className="card-body">
                    <form onSubmit={this.submitLogin}>
                      <div className="form-group">
                        <label htmlFor="usuario">Usuario</label>
                        <input
                          type="text"
                          name="usuario"
                          className="form-control"
                          onChange={this.handleInputChange}
                          defaultValue={this.state.usuario}
                          maxLength="10"
                          autoComplete="off"
                        />
                        {this.validator.message(
                          "usuario",
                          this.state.usuario,
                          "required|alpha_num"
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <input
                          type="password"
                          name="contrasenia"
                          className="form-control"
                          onChange={this.handleInputChange}
                          defaultValue={this.state.contrasenia}
                          maxLength="20"
                        />
                        {this.validator.message(
                          "contrasenia",
                          this.state.contrasenia,
                          "required|alpha_num"
                        )}
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="chkRemember"
                          id="chkRemember"
                          onChange={this.toggleRememberMe}
                          checked={this.state.rememberMe}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="chkRemember"
                        >
                          Recordar Usuario
                        </label>
                      </div>
                      {this.state.loading === 0 && (
                        <input
                          type="submit"
                          value="Aceptar"
                          className="btn btn-warning btn-block mt-3"
                        />
                      )}
                      {this.state.loading === 1 && (
                        <div className="text-center mt-3">
                          <h6>Cargando...</h6>
                          <div
                            className="spinner-border text-warning"
                            role="status"
                            aria-hidden="true"
                          ></div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default Login;

//import React, { Component } from "react";
import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import Encuesta from "./components/Encuesta";
import EncuestaDetalle from "./components/EncuestaDetalle";
import ReporteEncuestas from "./components/ReporteEncuestas";
import { isAuthenticated } from "./repository";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* Configuración de Rutas y Páginas */}
        <Switch>
          <Route exact path="/" component={Login} />

          <PrivateRoute exact path="/encuesta">
            <Encuesta />
          </PrivateRoute>

          <Route
            exact
            path="/EncuestaDetalle/:id/:source"
            render={(props) => <EncuestaDetalle {...props} />}
          />
          <Route
            exact
            path="/EncuestaDetalle/:id/:source/:pidSucursal/:pfechaInicio/:pfechaFin"
            render={(props) => <EncuestaDetalle {...props} />}
          />
          <PrivateRoute exact path="/busquedaPorSucursal">
            <ReporteEncuestas />
          </PrivateRoute>
          <Route
            exact
            path="/busquedaPorSucursal/:idSucursal/:fechaInicio/:fechaFin"
            render={(props) => <ReporteEncuestas {...props} />}
          />
          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>
          <Route component={NotFound} />
          {/*
          <PrivateRoute exact path="/test">
            <Test />
          </PrivateRoute>
          */}
        </Switch>
      </BrowserRouter>
    );
  }
}

const auth = isAuthenticated();

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...props }) { 
  return (
    <Route
      {...props}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default Router;

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import {
  obtieneSucursalesEncargados,
  obtienetipoVisita,
  obtieneTipoCliente,
  agregaEntrevista,
  guardaFotoAzure,
  agregaFotosDetalle,
} from "../repository";
import Swal from "sweetalert";
import NavBar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

class Encuesta extends Component {
  constructor() {
    super();
    this.obtieneSucursalesCRM = this.obtieneSucursalesCRM.bind(this);
    this.obtieneTipoVisita = this.obtieneTipoVisita.bind(this);
    this.obtieneTipoCliente = this.obtieneTipoCliente.bind(this);    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleComboChange = this.handleComboChange.bind(this);   
    this.limpiarControles = this.limpiarControles.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.state = {
      sucursales: [],
      tipoVisita: [],
      tipoCliente: [],
      idSucursal: -1,
      iTipoVisita: -1,
      iTipoCliente: -1,
      nombreCliente: "",
      domicilio: "",
      mayorCompra: "",
      problematicaCompra: "",
      requerimientosCompra: "",
      rutaFoto: "",
      correo: "",
      telefonoLocal: "",
      antiguedad: "",
      loading: 0,
      telefonoMovil: "",
      razonNoCompra: "",
      selectedFile: null,
      serviceProperties: null,
      idEncuesta: 0,
      foto: [],
      loading2: 1,
    };
  }

  handleComboChange(event) {
    if (event.target.name === "Almacen") {
      this.setState({
        idSucursal: event.target.value,
      });
    }
    if (event.target.name === "tipoVisita") {
      this.setState({ iTipoVisita: event.target.value });
    }
    if (event.target.name === "tipoCliente") {
      this.setState({ iTipoCliente: event.target.value });
    }
  }

  UNSAFE_componentWillMount() {
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    });
    this.obtieneSucursalesCRM();
    this.obtieneTipoVisita();
    this.obtieneTipoCliente();
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  obtieneSucursalesCRM = () => {
    obtieneSucursalesEncargados()
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.idAlmacen, text: item.strDescripcion };
          });
          this.setState({
            sucursales: [{ value: 0, text: "[Seleccione]" }].concat(items),
          });
        } else {
          Swal(
            "Error obtener la información [Sucursales]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Sucursales]", err, "error")
      );
  };

  obtieneTipoVisita = () => {
    obtienetipoVisita()
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.iTipoVisita, text: item.strDescripcion };
          });
          this.setState({
            tipoVisita: [{ value: 0, text: "[Seleccione]" }].concat(items),
          });
        } else {
          Swal(
            "Error obtener la información [TipoVisita]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [TipoVisita]", err, "error")
      );
  };

  obtieneTipoCliente = () => {
    obtieneTipoCliente()
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.iTipocliente, text: item.strDescripcion };
          });
          this.setState({
            tipoCliente: [{ value: 0, text: "[Seleccione]" }].concat(items),
            loading2: 0,
          });
        } else {
          Swal(
            "Error obtener la información [TipoCliente]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [TipoCliente]", err, "error")
      );
  };

  limpiarControles = () => {
    this.setState({
      idSucursal: -1,
      iTipoVisita: -1,
      iTipoCliente: -1,
      nombreCliente: "",
      domicilio: "",
      mayorCompra: "",
      problematicaCompra: "",
      requerimientosCompra: "",
      rutaFoto: "",
      correo: "",
      telefonoLocal: "",
      antiguedad: "",
      loading: 0,
      telefonoMovil: "",
      sucursales: [],
      tipoVisita: [],
      tipoCliente: [],
      razonNoCompra: "",
    });
    this.obtieneSucursalesCRM();
    this.obtieneTipoVisita();
    this.obtieneTipoCliente();
  };

  onChangeHandler(event) {
    event.preventDefault();
    if (event.target.name === "fotografia") {
      //comparamos para ver si no viene vacio el control
      if (event.target.files[0] !== undefined) {
        //validamos si hemos cargado 10 fotos anteriormente
        if (this.state.foto.length < 10) {
          //asignamos el objeto de archivo a un archivo temporal
          this.setState({ selectedFile: event.target.files[0] }, function () {
            //tomamos el archivo temporal en un var
            var fileName = this.state.selectedFile.name;
            var index = -1;
            //comparamos si tenemos vacio el arreglo de archivos (los que se cargaran al azure)
            if (this.state.foto !== undefined) {
              //leemos el arreglo para buscar coincidencias
              for (var i = 0; i < this.state.foto.length; i++) {
                //comparamos por nombre de archivo
                if (this.state.foto[i].name === fileName) {
                  index = i;
                  break; //  <- salimos del for una vez encontrada una coincidencia
                }
              }
              if (index !== -1) {
                Swal("Aviso", "No puede guardarse la misma foto.", "warning");
              } else {
                //guardamos el archivo nuevo junto a los demas
                this.setState(
                  { foto: [...this.state.foto, this.state.selectedFile] },
                  function () {
                    //validar info de archivo
                  }
                );
              }
            } else {
              //se guarda por primera vez
              this.setState(
                { foto: [...this.state.foto, this.state.selectedFile] },
                function () {
                  //validar del arreglo
                }
              );
            }
          });
        } else {
          Swal("Aviso", "Sólo se permite agregar 10 fotos", "warning");
        }
      }

      event.target.value = null;
    } else {
      alert("archivo vacio");
    }
  }

  eliminarFoto = async (e) => {
    e.preventDefault();
    if (e.target.name === "btnEliminar") {
      var array = [...this.state.foto]; //clonamos arreglo para hacer modificaciones de "recorte"
      var fileName = e.target.value; //obtenemos el nombre del archivo
      var index = -1;
      for (var i = 0; i < this.state.foto.length; i++) {
        if (this.state.foto[i].name === fileName) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ foto: array });
      }
    }
  };

  guardaImagen = async (idEncuesta) => {
    if (this.state.foto !== null) {
      for (var i = 0; i < this.state.foto.length; i++) {
        const file = this.state.foto[i];
        await guardaFotoAzure(file, idEncuesta);
        agregaFotosDetalle(idEncuesta, file.name);
      }
    } else {
      //console.log("archivo vacio");
    }
  };

  guardarEncuesta = async (e) => {
    e.preventDefault();
    /* poner en falso para omitir validaciones !this.validator.allValid() y hacer pruebas */
    if (this.validator.allValid()) {
      const guarda = await Swal("Confirmación", "¿Desea guardar la encuesta?", {
        buttons: {
          cancel: "Cancelar",
          aceptar: true,
        },
      }).then((value) => {
        switch (value) {
          case "aceptar":
            return true;
          case "cancel":
            //  msj cancelar
            break;
          default:
          // opcion cuando no se clickeo nada
        }
      });

      if (guarda) {
        this.setState({ loading: 1 });
        /* Comentar esta seccion para hacer pruebas de guardado de fotografias */
        var idUsuario = localStorage.getItem("idUsuario");
        //console.log(idUsuario);
        var guardo = await agregaEntrevista(
          idUsuario,
          this.state.idSucursal,
          this.state.nombreCliente,
          this.state.domicilio,
          this.state.iTipoCliente,
          this.state.iTipoVisita,
          this.state.mayorCompra,
          this.state.problematicaCompra,
          this.state.requerimientosCompra,
          this.state.rutaFoto,
          this.state.correo,
          this.state.telefonoLocal,
          this.state.antiguedad,
          this.state.telefonoMovil,
          this.state.razonNoCompra
        );
        /*  ************************* hasta aqui **************************************   */
        /* descomentar la variable  para hacer prueba sin guardar registros de sql de entrevista    */
        // var guardo = 1000000; // <- es un id(identity) devuelto por el guardado de sql variable tipo OUTPUT
        /* ****************************hasta aqui ***************************************   */
        if (guardo !== 0) {
          var idEncuesta = guardo;
          //console.log("idEncuesta:" +idEncuesta);
          await this.guardaImagen(idEncuesta);
          this.setState({ loading: 0 });
          Swal("La encuesta se ha guardado", "", "success", {
            buttons: { aceptar: true },
          }).then((value) => {
            switch (value) {
              case "aceptar":
                window.location = "/Home";
                break;
              default:
                window.location = "/Home";
                break;
            }
          });
        } else {
          Swal("Fallo al Guardar", "La encuesta no pudo ser grabada", "error");
          this.setState({ loading: 0 });
        }
      }
    } else {
     // console.log("ValidatorFailed");
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: 0 });
      Swal("Aviso", "Favor de validar la información", "warning");
    }
  };

  render() {
    return (
      <div>
        {/* NAVBAR */}
        <NavBar />
        {/* HEADER */}
        <Header
          title="Encuesta de Cliente"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fas fa-file-alt"
        />
        {/* ENCUESTA*/}
        <section id="encuesta">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-2 mt-2 py-2">
                <div className="row">
                  <div className="col-md-3 mb-2">
                    <Link
                      to={"/home"}
                      className="btn btn-secondary btn-block mb-3"
                    >
                      <i className="fas fa-arrow-left"></i> Regresar
                    </Link>
                  </div>
                </div>
                {this.state.loading2 === 1 && (
                  <div className="text-center mt-3">
                    <h6>Cargando...</h6>
                    <div
                      className="spinner-border text-warning"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                )}

                {this.state.loading2 === 0 && (
                  <div className="card">
                    <div className="card-header">
                      <h5 className="text-center">Nueva Encuesta</h5>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            Sucursal <label className="text-danger">*</label>
                          </label>
                          <select
                            name="Almacen"
                            key="idAlmacen"
                            className="form-control"
                            onChange={this.handleComboChange}
                          >
                            {this.state.sucursales.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.text}
                              </option>
                            ))}
                          </select>
                          {this.validator.message(
                            "idSucursal",
                            this.state.idSucursal,
                            "required|numeric|min:1,num"
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            Tipo de Visita{" "}
                            <label className="text-danger">*</label>{" "}
                          </label>
                          <select
                            name="tipoVisita"
                            key="iTipoVisita"
                            className="form-control"
                            onChange={this.handleComboChange}
                          >
                            {this.state.tipoVisita.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.text}
                              </option>
                            ))}
                          </select>
                          {this.validator.message(
                            "tipoVisita",
                            this.state.iTipoVisita,
                            "required|numeric|min:1,num"
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            Tipo de Cliente{" "}
                            <label className="text-danger">*</label>
                          </label>
                          <select
                            name="tipoCliente"
                            key="iTipoCliente"
                            className="form-control"
                            onChange={this.handleComboChange}
                          >
                            {this.state.tipoCliente.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.text}
                              </option>
                            ))}
                          </select>
                          {this.validator.message(
                            "tipoCliente",
                            this.state.iTipoCliente,
                            "required|numeric|min:1,num"
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="usuario">
                            Nombre Cliente{" "}
                            <label className="text-danger">*</label>
                          </label>
                          <input
                            type="text"
                            value={this.state.nombreCliente}
                            name="nombreCliente"
                            key="nombreCliente"
                            onChange={this.handleInputChange}
                            className="form-control"
                            maxLength="150"
                            autoComplete="off"
                          />
                          {this.validator.message(
                            "nombreCliente",
                            this.state.nombreCliente,
                            "required|string"
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            Domicilio <label className="text-danger">*</label>
                          </label>
                          <input
                            type="text"
                            name="domicilio"
                            value={this.state.domicilio}
                            onChange={this.handleInputChange}
                            className="form-control"
                            maxLength="150"
                            autoComplete="off"
                          />
                          {this.validator.message(
                            "domicilio",
                            this.state.domicilio,
                            "required|string"
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            Correo <label className="text-danger">*</label>
                          </label>
                          <input
                            type="text"
                            name="correo"
                            value={this.state.correo}
                            onChange={this.handleInputChange}
                            className="form-control"
                            maxLength="60"
                            autoComplete="off"
                          />
                          {this.validator.message(
                            "correo",
                            this.state.correo,
                            "required|email"
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            Teléfono Local{" "}
                            <label className="text-danger">*</label>
                          </label>
                          <input
                            type="text"
                            name="telefonoLocal"
                            value={this.state.telefonoLocal}
                            onChange={this.handleInputChange}
                            className="form-control"
                            maxLength="30"
                            autoComplete="off"
                          />
                          {this.validator.message(
                            "telefonoLocal",
                            this.state.telefonoLocal,
                            "required|string"
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="usuario">
                            Celular <label className="text-danger">*</label>
                          </label>
                          <input
                            type="text"
                            name="telefonoMovil"
                            value={this.state.telefonoMovil}
                            onChange={this.handleInputChange}
                            className="form-control"
                            maxLength="30"
                            autoComplete="off"
                          />
                          {this.validator.message(
                            "telefonoMovil",
                            this.state.telefonoMovil,
                            "required|string"
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            ¿Cuanto compra en Suspensión?
                          </label>
                          <input
                            type="text"
                            name="mayorCompra"
                            value={this.state.mayorCompra}
                            onChange={this.handleInputChange}
                            className="form-control"
                            maxLength="50"
                            autoComplete="off"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="usuario">
                            Si no ha comprado en GOVI ¿Cuál es el Motivo?
                          </label>
                          <input
                            type="text"
                            name="razonNoCompra"
                            value={this.state.razonNoCompra}
                            onChange={this.handleInputChange}
                            className="form-control"
                            maxLength="100"
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            Antigüedad de ser cliente GOVI
                          </label>
                          <input
                            type="text"
                            value={this.state.antiguedad}
                            onChange={this.handleInputChange}
                            name="antiguedad"
                            className="form-control"
                            maxLength="50"
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="usuario">
                            ¿Ha experimentado problemas con nuestra marca?
                          </label>
                          <input
                            type="text"
                            value={this.state.problematicaCompra}
                            onChange={this.handleInputChange}
                            name="problematicaCompra"
                            className="form-control"
                            maxLength="150"
                            autoComplete="off"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="usuario">
                            Sugerencias para estar con nuestra Marca
                          </label>
                          <input
                            type="text"
                            name="requerimientosCompra"
                            value={this.state.requerimientosCompra}
                            onChange={this.handleInputChange}
                            className="form-control"
                            maxLength="150"
                            autoComplete="off"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="usuario">Fotografía(s)</label>
                          <input
                            type="file"
                            name="fotografia"
                            className="form-control"
                            multiple="0"
                            accept="image/*"
                            onChange={this.onChangeHandler}
                          />
                        </div>

                        {this.state.foto !== null && (
                          <div className="py-2">
                            {this.state.foto.map((item) => {
                              return (
                                <div
                                  className="container  form-group "
                                  name="divContain"
                                  key={item.name}
                                >
                                  <div className="row" name="divRow">
                                    <div className="col" name="divCol">
                                      <label
                                        htmlFor="usuario"
                                        className="col"
                                        name="imgName"
                                      >
                                        {item.name
                                          ? item.name.length > 15
                                            ? item.name.substring(0, 15) +
                                              ".." +
                                              item.name.substring(
                                                item.name.length,
                                                item.name.length - 4
                                              )
                                            : item.name
                                          : ""}
                                      </label>
                                    </div>

                                    <div className="col-md-3">
                                      <button
                                        type="submit"
                                        className="btn btn-danger btn-block"
                                        value={item.name}
                                        onClick={this.eliminarFoto}
                                        name="btnEliminar"
                                      >
                                        <i
                                          className="far fa-trash-alt"
                                          name="btnEliminarIcon"
                                        ></i>
                                        &nbsp;Eliminar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}

                        <div className="d-flex flex-column align-items-end">
                          {this.state.loading === 0 && (
                            <div className="col-md-3">
                              <button
                                type="submit"
                                value="Guardar"
                                onClick={this.guardarEncuesta}
                                className="btn btn-warning btn-block"
                              >
                                <i className="far fa-save"></i>&nbsp;Guardar
                              </button>
                            </div>
                          )}
                        </div>
                        {this.state.loading === 1 && (
                          <div className="text-center mt-3">
                            <h6>Guardando, espere por favor...</h6>
                            <div
                              className="spinner-border text-warning"
                              role="status"
                              aria-hidden="true"
                            ></div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}
export default Encuesta;

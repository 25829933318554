
import React,{Component } from 'react'

class Footer extends Component {
    render() {
      return (
        <footer id="main-footer" className="bg-dark text-white mt-5 p-1">
          <div className="container">
            <div className="row">
              <div className="col">
                <p className="text-center">
                  Copyright &copy;
                  <span id="yearFooter">{new Date().getFullYear()}&nbsp;</span>
                  GOVI
                </p>
              </div>
            </div>
          </div>
        </footer>
      );
    }
  }
  
  export default Footer;
  
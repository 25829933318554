import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import NavBarItem from "./NavBarItem";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      cartItems: 0,
      orderItems: 0,
    };
  }

  logOut() {
    sessionStorage.clear();
  }

  componentDidMount() {}
  render() {
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-2 sticky-top">
        <div className="container">
          <NavLink to="/home" className="navbar-brand mb-2 text-white-50">
            &nbsp;GoviNet
          </NavLink>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <small className="mr-2">MENU</small>
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav">
              {user.map((item, index) => (
                <NavBarItem
                  item={item}
                  key={index}
                  cartItems={this.state.cartItems}
                  orderItems={this.state.orderItems}
                />
              ))}
            </ul>
            <ul className="navbar-nav ml-2">
              <li className="nav-item"></li>
              <a className="nav-link" href="/" onClick={this.logOut}>
                <h6>Salir</h6>
              </a>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;

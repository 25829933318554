import React from "react";

const NotFound = () => {
  return (
    <div className="container bg-warning text-black mt-2 p-1">
      <div className="row">
        <div className="col">
          <h6>
            <p className="text-center">
              <br />
              Página no encontrada.
              <br />              
              <a href="/">Clic aquí para Entrar al Sistema.</a>
            </p>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

import React from "react";
import "./css/App.css";
/*Prime React Components*/
import 'primereact/resources/themes/rhea/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Router from "./Router";

function App() 
{
  return (
    <div className="App">
      <Router/>
    </div>
  );
}

export default App;

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import Swal from "sweetalert";
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { obtieneEncuestaPorId, obtieneFotosEncuesta } from "../repository";
import Global from "./Global";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

class EncuestaDetalle extends Component {
  constructor() {
    super();
    this.consultaEncuesta = this.consultaEncuesta.bind(this);
    this.consultaFotos = this.consultaFotos.bind(this);
    this.state = {
      items: {},
      message: "",
      source: "",
      foto: [],
      status: null,
      open: false,
      isOpen: false,
      photoIndex: 0,
      abrirFoto: "",
      loading: 1,
    };
  }

  consultaEncuesta = () => {
    try {
      this.setState({ items: { undefined } });
      var id = this.props.match.params.id;
      var source = this.props.match.params.source;

      this.setState({ source: source });
      obtieneEncuestaPorId(id)
        .then((response) => {
          if (response.status === "success" && response.result) {
            var items = response.result[0];
            this.setState({
              status: "success",
              items: items,
              loading: 0,
            });
          } else {
            Swal("Error obtener la información [Encuesta]", "error", "error");
          }
        })
        .catch((err) =>
          Swal("Error obtener la información [Encuesta]", "error", "error")
        );
    } catch (err) {
      Swal("Error obtener la información [Encuesta]", "error", "error");
    }
  };

  consultaFotos = () => {
    try {
      var idEncuesta = this.props.match.params.id;
      obtieneFotosEncuesta(idEncuesta)
        .then((response) => {
          if (response.status === "success") {
            this.setState({
              status: "success",
              foto: response.result,
            });
          } else {
            Swal("Error obtener la información [Fotos]", "error", "error");
          }
        })
        .catch((err) =>
          Swal("Error obtener la información [Fotos]", "error", "error")                  
        );
    } catch (err) {
      Swal("Error obtener la información [foto]", "error", "error");
    }
  };

  componentDidMount() {
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    });
    this.consultaEncuesta();
    this.consultaFotos();
  }

  render() {
    return (
      <div>
        {/* NAVBAR */}
        {!this.state.isOpen && <NavBar />}

        {/* HEADER */}
        <Header
          title="Encuesta de Cliente"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fas fa-file-alt"
        />
        {/* ENCUESTA*/}
        <section id="encuesta">
          <div className="container">
            <div className="row">
              <div className="col-md-3 mt-3">
                <Link
                  to={
                    this.state.source === "1"
                      ? "/Home"
                      : "/BusquedaPorSucursal/" +
                        this.props.match.params.pidSucursal +
                        "/" +
                        this.props.match.params.pfechaInicio +
                        "/" +
                        this.props.match.params.pfechaFin
                  }
                  className="btn btn-secondary btn-block mb-2"
                >
                  <i className="fas fa-arrow-left"></i> Regresar
                </Link>
              </div>
              <div className="col-md-12 mx-auto mt-3">
                {this.state.loading === 1 && (
                  <div className="text-center mt-3">
                    <h6>Cargando...</h6>
                    <div
                      className="spinner-border text-warning"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                )}
                <div className="card">
                  <div className="card-header">
                    <h4 className="text-center">
                      Detalle Encuesta # {this.state.items.id}{" "}
                    </h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="form-group">
                        <label htmlFor="usuario">Sucursal</label>
                        <input
                          type="text"
                          name="Almacen"
                          key="Almacen"
                          defaultValue={this.state.items.NombreAlmacen}
                          className="form-control"
                          readOnly="1"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">Fecha</label>
                        <input
                          type="text"
                          name="tipoVisita"
                          key="tipoVisita"
                          defaultValue={this.state.items.dFechaEntrevista}
                          className="form-control"
                          readOnly="1"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">Tipo de Visita</label>
                        <input
                          type="text"
                          name="tipoVisita"
                          key="tipoVisita"
                          defaultValue={this.state.items.TipoVisita}
                          className="form-control"
                          readOnly="1"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">Tipo de Cliente</label>
                        <input
                          type="text"
                          name="tipoVisita"
                          key="tipoVisita"
                          defaultValue={this.state.items.TipoCliente}
                          className="form-control"
                          readOnly="1"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">Nombre Cliente</label>
                        <input
                          type="text"
                          name="nombreCliente"
                          key="nombreCliente"
                          defaultValue={this.state.items.strNombreCliente}
                          className="form-control"
                          readOnly="1"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">Domicilio</label>
                        <input
                          type="text"
                          name="domicilio"
                          defaultValue={this.state.items.strDomicilio}
                          className="form-control"
                          maxLength="200"
                          readOnly="1"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">Correo</label>
                        <input
                          type="text"
                          name="correo"
                          defaultValue={this.state.items.strCorreo}
                          readOnly="1"
                          className="form-control"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">Teléfono Local</label>
                        <input
                          type="text"
                          name="telefonoLocal"
                          defaultValue={this.state.items.strTelefonoLocal}
                          readOnly="1"
                          className="form-control"
                          maxLength="50"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">Celular</label>
                        <input
                          type="text"
                          name="telefonoMovil"
                          defaultValue={this.state.items.strTelefonoMovil}
                          readOnly="1"
                          className="form-control"
                          maxLength="50"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">
                          ¿Cuanto compra en Suspensión?
                        </label>
                        <input
                          type="text"
                          name="mayorCompra"
                          defaultValue={this.state.items.strMayorCompra}
                          className="form-control"
                          maxLength="200"
                          readOnly="1"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">
                          Si no ha comprado en GOVI ¿Cuál es el Motivo?
                        </label>
                        <input
                          type="text"
                          name="razonNoCompra"
                          defaultValue={this.state.items.strRazonNoCompra}
                          className="form-control"
                          maxLength="100"
                          readOnly="1"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">
                          Antigüedad de ser cliente GOVI
                        </label>
                        <input
                          type="text"
                          name="antiguedad"
                          defaultValue={this.state.items.strAntiguedad}
                          readOnly="1"
                          className="form-control"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">
                          ¿Ha experimentado problemas con nuestra marca?
                        </label>
                        <input
                          type="text"
                          name="problematicaCompra"
                          defaultValue={this.state.items.strProblematicaCompra}
                          readOnly="1"
                          className="form-control"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="usuario">
                          Sugerencias para estar con nuestra Marca
                        </label>
                        <input
                          type="text"
                          name="requerimientosCompra"
                          defaultValue={
                            this.state.items.strRequerimientosCompra
                          }
                          readOnly="1"
                          className="form-control"
                          maxLength="100"
                          autoComplete="off"
                        />
                      </div>
                      {this.state.status === "success" &&
                        this.state.foto !== undefined && (
                          <div name="divContenedor" className="container">
                            <label name="lblUsuario" htmlFor="usuario">
                              Fotografía(s)
                            </label>
                            <div
                              name="divContenedorInterno"
                              className="container"
                            >
                              <div className="row" name="divRow">
                                {this.state.foto.map((items) => {
                                  return (
                                    <div
                                      className="col-md-4 card-group mt-3"
                                      name="divCardGrupo"
                                      key={items.id}
                                    >
                                      <div
                                        className="card text-center"
                                        name="divCard"
                                      >
                                        <div
                                          className="card-header"
                                          name="divHeader"
                                        >
                                          {/*<h6>{items.strNombreFoto} </h6> */}
                                          <h6
                                            name="itemlabel"
                                            className="fluid"
                                          >
                                            {" "}
                                            {items.strNombreFoto
                                              ? items.strNombreFoto.length > 15
                                                ? items.strNombreFoto.substring(
                                                    0,
                                                    10
                                                  ) + "..."
                                                : items.strNombreFoto
                                              : ""}
                                          </h6>
                                        </div>
                                        <div
                                          className="card-img-center"
                                          name="divCardImg"
                                        >
                                          <img
                                            name="foto"
                                            style={{
                                              maxWidth: 100 + "%",
                                              height: 130 + "px",
                                              objectFit: "contain",
                                            }}
                                            src={
                                              Global.UrlFotosWeb +
                                              items.idEncuesta +
                                              "_" +
                                              items.strNombreFoto
                                            }
                                            alt={items.strNombreFoto}
                                            onClick={() =>
                                              this.setState({
                                                isOpen: true,
                                                abrirFoto: items.strNombreFoto,
                                              })
                                            }
                                          ></img>
                                          {this.state.isOpen &&
                                            items.strNombreFoto ===
                                              this.state.abrirFoto && (
                                              <div>
                                                <Lightbox
                                                  mainSrc={
                                                    Global.UrlFotosWeb +
                                                    items.idEncuesta +
                                                    "_" +
                                                    items.strNombreFoto
                                                  }
                                                  onCloseRequest={() =>
                                                    this.setState({
                                                      isOpen: false,
                                                    })
                                                  }
                                                  closeLabel={"Cerrar"}
                                                  enableZoom={true}
                                                  imageCaption={
                                                    items.strNombreFoto
                                                  }
                                                  clickOutsideToClose={true}
                                                  imagePadding={30}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}

export default EncuestaDetalle;
